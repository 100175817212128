<template>
  <b-modal
    id="create-creative-briefing-modal"
    ref="create-creative-briefing-modal"
    title="Create Creative Briefing"
    centered
    hide-footer
    no-close-on-backdrop
    size="sm"
    @hidden="creativeBriefingName = ''"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <form
      class="creative-briefing-form"
      @submit.prevent="handleSubmit"
    >
      <validation-provider
        #default="{ errors }"
        ref="creativeBriefingName"
        name="Creative Briefing Name"
        rules="required"
      >
        <HeroInputText
          id="creative-briefing-name"
          v-model="creativeBriefingName"
          class="creative-briefing-name"
          label="Name"
          required
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        >
          <template #append>
            <HeroButtonAction
              variant="primary"
              type="submit"
              class="btn-action"
            >
              Save
            </HeroButtonAction>
          </template>
        </HeroInputText>
      </validation-provider>
    </form>
  </b-modal>
</template>
<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import Constant from '@/utils/Constant'

export default {
  components: {
    HeroButtonAction,
    HeroInputText,
  },
  props: {
    opportunityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      creativeBriefingName: '',
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.showOverlay = true
        const isCreativeBriefingNameValid = (await this.$refs.creativeBriefingName.validate()).valid
        if (isCreativeBriefingNameValid && this.opportunityId) {
          const payload = {
            opportunity_id: this.opportunityId,
            name: this.creativeBriefingName,
            created_by: this.$store.getters['heroAiAuthentications/fullName'],
          }

          await axiosInstance.post(Constant.apiURL.campaignCreativeBrief.create, payload)

          this.$bvModal.hide('create-creative-briefing-modal')
          this.$swal({ ...SweetAlert.success, text: 'Create Successfully' })
          this.$emit('create-success')
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-action {
  width: 90px;
  min-height: 38px;
  padding: 8px;
}

.creative-briefing-form {
  ::v-deep .input-group {
    column-gap: 8px;
    .form-control {
      border-radius: 0.357rem;
      border: 1px solid #d8d6de;
      &.is-invalid {
        border-color: #ea5455;
      }
      &:not(.is-invalid) {
        &:focus {
          border-color: #7367f0;
        }
      }
    }
  }
  .creative-briefing-name {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
}
</style>
