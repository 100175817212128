<template>
  <b-modal
    id="creative-briefing-list-modal"
    ref="creative-briefing-list-modal"
    title="Creative Briefing List"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="getCreativeBriefingList"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <template #modal-header="{ close }">
      <div class="creative-briefing-list-modal-header">
        <h5 class="modal-title">
          Creative Briefing List
        </h5>
        <div class="creative-briefing-list-modal-header-action">
          <HeroButtonAction
            type="button"
            variant="primary"
            class="btn-action"
            @click="$bvModal.show('create-creative-briefing-modal')"
          >
            Create
          </HeroButtonAction>
          <button type="button" aria-label="Close" class="close" @click="close">
            ×
          </button>
        </div>
      </div>
    </template>
    <div
      v-if="creativeBriefingList.length"
      class="creative-briefing-list"
    >
      <div
        v-for="creativeBriefing in creativeBriefingList"
        :key="creativeBriefing.unique_id"
        class="box"
      >
        <div class="creative-briefing-list-header">
          <div :id="`creative-briefing-name-${creativeBriefing.unique_id}`" class="creative-briefing-name">
            {{ creativeBriefing.name }}
          </div>
          <b-tooltip :target="`creative-briefing-name-${creativeBriefing.unique_id}`" triggers="hover">
            {{ creativeBriefing.name }}
          </b-tooltip>
          <div class="creative-briefing-action">
            <b-form-checkbox
              :id="`editable-${creativeBriefing.unique_id}`"
              v-model="creativeBriefing.editable"
              :name="`editable-${creativeBriefing.unique_id}`"
              @change="handleEditable(creativeBriefing.unique_id)"
            >
              Editable
            </b-form-checkbox>
            <b-dropdown
              right
              variant="none"
              class="dropdown-action"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="24"
                  class="d-flex d-md-none"
                />
              </template>
              <b-dropdown-item
                target="_blank"
                :href="`/creative-briefing/${opportunityId}/${creativeBriefing.unique_id}`"
              >
                <HeroButtonAction
                  type="button"
                  variant="primary"
                >
                  View
                </HeroButtonAction>
              </b-dropdown-item>
              <div class="h-line" />
              <b-dropdown-item>
                <HeroButtonAction
                  type="button"
                  variant="primary"
                  :disabled="!creativeBriefing.campaign_id"
                  target="_blank"
                  :href="`/campaigns/${creativeBriefing.campaign_id}`"
                >
                  Campaign
                </HeroButtonAction>
              </b-dropdown-item>
            </b-dropdown>
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="!creativeBriefing.campaign_id"
              target="_blank"
              :href="`/campaigns/${creativeBriefing.campaign_id}`"
            >
              Campaign
            </HeroButtonAction>
            <HeroButtonAction
              type="button"
              variant="primary"
              target="_blank"
              :href="`/creative-briefing/${opportunityId}/${creativeBriefing.unique_id}`"
            >
              View
            </HeroButtonAction>
          </div>
        </div>
        <div class="creative-briefing-list-body">
          <feather-icon
            icon="GlobeIcon"
            size="24"
          />
          <HeroInputText
            id="creative-briefing-link"
            class="creative-briefing-link"
            :value="`${currentHost}/creative-briefing/${opportunityId}/${creativeBriefing.unique_id}`"
            disabled
          />
          <HeroButtonAction
            v-b-tooltip.hover="isCopied ? 'Copied' : 'Copy'"
            type="button"
            variant="primary"
            class="btn-action"
            @click="copyToClipboard(`${currentHost}/creative-briefing/${opportunityId}/${creativeBriefing.unique_id}`)"
          >
            Copy Link
          </HeroButtonAction>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      Creative Briefing not found.
    </div>
    <CreateCreativeBriefingModal
      :opportunity-id="opportunityId"
      @create-success="handleCreateSuccess"
    />
  </b-modal>
</template>

<script>

import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import SweetAlert from '@/services/SweetAlert'
import CreateCreativeBriefingModal from '@/views/heroai/creative-briefing/components/CreateCreativeBriefingModal.vue'
import axiosInstance from '@/libs/axiosInstance'
import Constant from '@/utils/Constant'
import ErrorService from '@/services/ErrorService'

export default {
  components: {
    CreateCreativeBriefingModal,
    HeroInputText,
    HeroButtonAction,
  },
  props: {
    opportunityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      isCopied: false,
      creativeBriefingList: [],
    }
  },
  computed: {
    currentHost() {
      if (typeof window === 'undefined') return ''
      return window.location.host
    },
  },
  methods: {
    async getCreativeBriefingList() {
      try {
        this.showOverlay = true
        const { data: { data: creativeBriefingList } } = await axiosInstance.get(Constant.apiURL.campaignCreativeBrief.list(this.opportunityId))
        this.creativeBriefingList = creativeBriefingList.map(creativeBriefing => ({
          ...creativeBriefing,
          editable: !!creativeBriefing.editable,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async handleEditable(id) {
      try {
        const creativeBriefingIndex = this.creativeBriefingList.findIndex(creativeBriefing => creativeBriefing.unique_id === id)
        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to update?' })

        if (alertResult.value) {
          this.showOverlay = true
          await axiosInstance.patch(Constant.apiURL.campaignCreativeBrief.editable(id), {
            editable: this.creativeBriefingList[creativeBriefingIndex].editable ? 1 : 0,
          })
          this.$swal({ ...SweetAlert.success, text: 'Update Successfully' })
          return
        }

        this.creativeBriefingList[creativeBriefingIndex].editable = !this.creativeBriefingList[creativeBriefingIndex].editable
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    handleCreateSuccess() {
      this.getCreativeBriefingList()
      this.$emit('create-success')
    },

    copyToClipboard(text) {
      this.isCopied = true
      navigator.clipboard.writeText(text)
      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },
  },
}
</script>

<style scoped lang="scss">
.h-line {
  height: 1px;
  width: 100%;
  background: #D8D6DE;
}

#creative-briefing-list-modal {
  .creative-briefing-list-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .creative-briefing-list-modal-header-action {
    display: flex;
    align-items: flex-start;
  }

  .box {
    background: white;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #D8D6DE;
  }

  .btn-action {
    width: 90px;
    min-height: 38px;
    padding: 8px;
    margin: 0;
  }

  .creative-briefing-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .creative-briefing-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .creative-briefing-name {
        font-size: 1rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .creative-briefing-action {
        display: flex;
        align-items: center;
        column-gap: 8px;
        > .btn {
          display: none;
          width: 90px;
          min-height: 38px;
          padding: 8px;
          margin: 0;
          @media(min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        ::v-deep .dropdown-action {
          > .btn {
            display: flex;
            padding: 0;
          }
          .dropdown-menu {
            padding: 0;
            .dropdown-item {
              padding: 8px;
              &:hover {
                background: white;
              }
            }
          }
        }
      }
    }
  }
  .creative-briefing-list-body {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
    .creative-briefing-link {
      flex: 1 0 0%;
      margin-bottom: 0;
      ::v-deep .form-control {
        font-size: 0.875rem;
        @media(min-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
